@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Anybody&display=swap);
/* cyrillic */
@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/l/font?kit=FwZc7-Am2FI_-VOxaLDvvq27pmO73q3LkQ&skey=96867d716c89840e&v=v14)
    format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/l/font?kit=FwZc7-Am2FI_-VOxaLDvvq27rWO73q3LkQ&skey=96867d716c89840e&v=v14)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/l/font?kit=FwZc7-Am2FI_-VOxaLDvvq27rGO73q3LkQ&skey=96867d716c89840e&v=v14)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/l/font?kit=FwZc7-Am2FI_-VOxaLDvvq27omO73q3LkQ&skey=96867d716c89840e&v=v14)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Anybody', cursive;
  src: url('https://fonts.googleapis.com/css2?family=Anybody&display=swap');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/NeueHaasDisplayLight.46f0c2f7.ttf) format('truetype');
}

@font-face {
  font-family: 'Elemental End';
  font-style: normal;
  font-weight: 400;
  src: local('Elemental End'), url(/static/media/Elemental-End.f572cd59.ttf) format('truetype');
}
@font-face {
  font-family: 'Elemental End';
  font-style: italic;
  font-weight: 400;
  src: local('Elemental End'), url(/static/media/Elemental-End-italic.af4a3cd4.ttf) format('truetype');
}

body .walletconnect-modal__mobile__toggle a {
  color: #000;
}

.MuiTooltip-tooltip {
  background: #1a1a1a !important;
  border: 1px solid #00abe2;
  margin-bottom: 5px !important;
}

.backdrop-modal-no-top {
  z-index: inherit !important;
}

